import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { IParam, RetinopathyType } from '../model/IParam';
import { IResult } from '../model/IResult';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

    readonly FiveYearVtdrRetinoMin: number = this.appConfig.get('FiveYearVtdrRetinoMin');
    readonly FiveYearVtdrRetinoMod: number = this.appConfig.get('FiveYearVtdrRetinoMod');
    readonly FiveYearVtdrA1c: number = this.appConfig.get('FiveYearVtdrA1c');
    readonly FiveYearVtdrBase: number = this.appConfig.get('FiveYearVtdrBase');

    readonly FiveYearDmeRetinoMin: number = this.appConfig.get('FiveYearDmeRetinoMin');
    readonly FiveYearDmeRetinoMod: number = this.appConfig.get('FiveYearDmeRetinoMod');
    readonly FiveYearDmeA1c: number = this.appConfig.get('FiveYearDmeA1c');
    readonly FiveYearDmeBase: number = this.appConfig.get('FiveYearDmeBase');

    readonly FiveYearPdrRetinoMin: number = this.appConfig.get('FiveYearPdrRetinoMin');
    readonly FiveYearPdrRetinoMod: number = this.appConfig.get('FiveYearPdrRetinoMod');
    readonly FiveYearPdrA1c: number = this.appConfig.get('FiveYearPdrA1c');
    readonly FiveYearPdrBase: number = this.appConfig.get('FiveYearPdrBase');

    readonly ThreeYearVtdrRetinoMin: number = this.appConfig.get('ThreeYearVtdrRetinoMin');
    readonly ThreeYearVtdrRetinoMod: number = this.appConfig.get('ThreeYearVtdrRetinoMod');
    readonly ThreeYearVtdrA1c: number = this.appConfig.get('ThreeYearVtdrA1c');
    readonly ThreeYearVtdrBase: number = this.appConfig.get('ThreeYearVtdrBase');

    readonly ThreeYearDmeRetinoMin: number = this.appConfig.get('ThreeYearDmeRetinoMin');
    readonly ThreeYearDmeRetinoMod: number = this.appConfig.get('ThreeYearDmeRetinoMod');
    readonly ThreeYearDmeA1c: number = this.appConfig.get('ThreeYearDmeA1c');
    readonly ThreeYearDmeBase: number = this.appConfig.get('ThreeYearDmeBase');

    readonly ThreeYearPdrRetinoMin: number = this.appConfig.get('ThreeYearPdrRetinoMin');
    readonly ThreeYearPdrRetinoMod: number = this.appConfig.get('ThreeYearPdrRetinoMod');
    readonly ThreeYearPdrA1c: number = this.appConfig.get('ThreeYearPdrA1c');
    readonly ThreeYearPdrBase: number = this.appConfig.get('ThreeYearPdrBase');

    constructor(private appConfig: AppConfigService) { 
    }

    getResult(param: IParam): Observable<IResult> {
        //get the summation
        let summation: ISummation = this.getSummation(param);
        let result: IResult = {} as IResult;

        result.FiveYearVtdr = 1 - Math.exp(Math.exp(summation.FiveYearVtdr) * -this.FiveYearVtdrBase);
        result.FiveYearDme = 1 - Math.exp(Math.exp(summation.FiveYearDme) * -this.FiveYearDmeBase);
        result.FiveYearPdr = 1 - Math.exp(Math.exp(summation.FiveYearPdr) * -this.FiveYearPdrBase);
        result.ThreeYearVtdr = 1 - Math.exp(Math.exp(summation.ThreeYearVtdr) * -this.ThreeYearVtdrBase);
        result.ThreeYearDme = 1 - Math.exp(Math.exp(summation.ThreeYearDme) * -this.ThreeYearDmeBase);
        result.ThreeYearPdr = 1 - Math.exp(Math.exp(summation.ThreeYearPdr) * -this.ThreeYearPdrBase);

        return of(result)
                .pipe (
                    catchError(err => {
                        return throwError(err);
                    })
                );  
    }

    private getSummation(param: IParam): ISummation {
        let result: ISummation = {} as ISummation;
        //add the Retinopathy part
        if (param.Retinopathy == RetinopathyType.Minimal)
        {
            result.FiveYearVtdr = this.FiveYearVtdrRetinoMin;
            result.FiveYearDme = this.FiveYearDmeRetinoMin;
            result.FiveYearPdr = this.FiveYearPdrRetinoMin;
            result.ThreeYearVtdr = this.ThreeYearVtdrRetinoMin;
            result.ThreeYearDme = this.ThreeYearDmeRetinoMin;
            result.ThreeYearPdr = this.ThreeYearPdrRetinoMin;
        }
        else if (param.Retinopathy == RetinopathyType.Moderate)
        {
            result.FiveYearVtdr = this.FiveYearVtdrRetinoMod;
            result.FiveYearDme = this.FiveYearDmeRetinoMod;
            result.FiveYearPdr = this.FiveYearPdrRetinoMod;
            result.ThreeYearVtdr = this.ThreeYearVtdrRetinoMod;
            result.ThreeYearDme = this.ThreeYearDmeRetinoMod;
            result.ThreeYearPdr = this.ThreeYearPdrRetinoMod;
        }
        else if (param.Retinopathy == RetinopathyType.None)
        {
            result.FiveYearVtdr = 0;
            result.FiveYearDme = 0;
            result.FiveYearPdr = 0;
            result.ThreeYearVtdr = 0;
            result.ThreeYearDme = 0;
            result.ThreeYearPdr = 0;
        }
        //add the A1c part
        result.FiveYearVtdr = result.FiveYearVtdr + param.Hgba1c * this.FiveYearVtdrA1c;
        result.FiveYearDme = result.FiveYearDme + param.Hgba1c * this.FiveYearDmeA1c;
        result.FiveYearPdr = result.FiveYearPdr + param.Hgba1c * this.FiveYearPdrA1c;
        result.ThreeYearVtdr = result.ThreeYearVtdr + param.Hgba1c * this.ThreeYearVtdrA1c;
        result.ThreeYearDme = result.ThreeYearDme + param.Hgba1c * this.ThreeYearDmeA1c;
        result.ThreeYearPdr = result.ThreeYearPdr + param.Hgba1c * this.ThreeYearPdrA1c;
        return result;
    }
}

interface ISummation {
    FiveYearVtdr: number,
    FiveYearDme: number,
    FiveYearPdr: number,
    ThreeYearVtdr: number,
    ThreeYearDme: number,
    ThreeYearPdr: number
}
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl, ValidatorFn } from "@angular/forms";
import { CalculatorService } from '../service/calculator.service';
import { IParam, RetinopathyType } from '../model/IParam'
import { IResult } from '../model/IResult';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

    queryForm: FormGroup;

    retinopathyChoice = [
        { value: RetinopathyType.None, viewValue: 'no retinopathy' },
        { value: RetinopathyType.Minimal, viewValue: 'mild NPDR' },
        { value: RetinopathyType.Moderate, viewValue: 'moderate-to-severe NPDR' }
    ];

    hgba1cMin: number = 4;
    hgba1cMax: number = 14;

    showProcessing: boolean = false;
    showResult: boolean = false;

    param: IParam;
    result: IResult;

    retinopathySelected: string;

    constructor(private formBuilder: FormBuilder,
        private calcSvc: CalculatorService) {
    }

    ngOnInit(): void {
        //init form
        this.queryForm = this.formBuilder.group({
            retinopathy: [null, [Validators.required]],
            hgba1c: [null, [Validators.required, validateRange(this.hgba1cMin, this.hgba1cMax)]]
        });
    }

    ngAfterViewInit(): void {
        this.queryForm.valueChanges.subscribe(i => {
            this.showResult = false;
            this.result = null;
        });
    }

    submitQuery() {
        this.showProcessing = true;
        this.param = {
            Retinopathy: this.queryForm.get("retinopathy").value,
            Hgba1c: this.queryForm.get("hgba1c").value
        };
        this.calcSvc.getResult(this.param).subscribe(
            data => {
                if (data) {
                    this.result = data;
                    this.showProcessing = false;
                    this.showResult = true;
                    //update retinopathySelected to show the same value as the dropdown display
                    for(let item of this.retinopathyChoice){
                        if (item.value == this.param.Retinopathy)
                            this.retinopathySelected = item.viewValue;
                    }
                }
            }
        );
    }

}

function validateRange(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        if (
            c.value !== null &&
            (isNaN(c.value) || c.value < min || c.value > max)
        ) {
            return { range: true };
        }
        return null;
    };
}


export interface IParam{
    Retinopathy: RetinopathyType,   
    Hgba1c: number
}

export enum RetinopathyType {
    None = 0,
    Minimal = 1,
    Moderate = 2
}